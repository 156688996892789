@import '../../../styles/customMediaQueries.css';

.root {

  display: flex;
  flex-direction: column;
}

.offerFormHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }

  &>svg {
    width: 74px;
    height: 74px;
    margin-bottom: 20px;

    & path,
    & rect {
      stroke: var(--colorWhite);
    }
  }

  & .heading {
    color: var(--colorGrey900);
    margin: 0 0 14px 0;
    padding: 0;
    line-height: 100%;
    font-size: 20px;
  }

  &>p {
    color: var(--colorGrey600);
    margin: 0;
    padding: 0;
    line-height: 150%;
    font-size: 16px;
  }
}

.input {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.field {
  margin: 25px 0 0px 0;

  @media (--viewportMedium) {
    margin: 22px 0 0px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.submitBtn {
  margin-top: 0;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  width: 100%;
}

.actionBtns {}